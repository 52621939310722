.pnlm-container {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  cursor: default;
  width: 100%;
  height: 100%;
  font-family: Helvetica, "Nimbus Sans L", "Liberation Sans", Arial, sans-serif;
  background: #f4f4f4;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  line-height: 1.4;
  contain: content;
}

.pnlm-container * {
  box-sizing: content-box;
}

.pnlm-ui {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.pnlm-grab {
  cursor: grab;
  cursor: url("../Assets/grab.svg") 12 8, default;
}

.pnlm-grabbing {
  cursor: grabbing;
  cursor: url("../Assets/grabbing.svg") 12 8, default;
}

.pnlm-sprite {
  background-image: url("../Assets/sprites.svg");
}

.pnlm-container:-moz-full-screen {
  height: 100% !important;
  width: 100% !important;
  position: static !important;
}
.pnlm-container:-webkit-full-screen {
  height: 100% !important;
  width: 100% !important;
  position: static !important;
}
.pnlm-container:-ms-fullscreen {
  height: 100% !important;
  width: 100% !important;
  position: static !important;
}
.pnlm-container:fullscreen {
  height: 100% !important;
  width: 100% !important;
  position: static !important;
}

.pnlm-render-container {
  cursor: inherit;

  /* Fix display bug in Safari 7 */
  position: absolute;
  height: 100%;
  width: 100%;
}

.pnlm-controls {
  margin-top: 4px;
  background-color: #fff;
  border: 1px solid #999;
  border-color: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  cursor: pointer;
  z-index: 2;
  /* Fix Safari fullscreen bug */
  -webkit-transform: translateZ(9999px);
  transform: translateZ(9999px);
}

.pnlm-control:hover {
  background-color: #f8f8f8;
}

.pnlm-controls-container {
  position: absolute;
  top: 0;
  left: 4px;
  z-index: 1;
}

.pnlm-zoom-controls {
  width: 26px;
  height: 52px;
}

.pnlm-zoom-in {
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  border-radius: 3px 3px 0 0;
}

.pnlm-zoom-out {
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  background-position: 0 -26px;
  border-top: 1px solid #ddd;
  border-top-color: rgba(0, 0, 0, 0.1);
  border-radius: 0 0 3px 3px;
}

.pnlm-fullscreen-toggle-button,
.pnlm-orientation-button,
.pnlm-hot-spot-debug-indicator {
  width: 26px;
  height: 26px;
}

.pnlm-hot-spot-debug-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 26px;
  height: 26px;
  margin: -13px 0 0 -13px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 13px;
  display: none;
}

.pnlm-orientation-button-inactive {
  background-position: 0 -156px;
}

.pnlm-orientation-button-active {
  background-position: 0 -182px;
}

.pnlm-fullscreen-toggle-button-inactive {
  background-position: 0 -52px;
}

.pnlm-fullscreen-toggle-button-active {
  background-position: 0 -78px;
}

.pnlm-panorama-info {
  position: absolute;
  bottom: 4px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0 3px 3px 0;
  padding-right: 10px;
  color: #fff;
  text-align: left;
  display: none;
  z-index: 2;
  /* Fix Safari fullscreen bug */
  -webkit-transform: translateZ(9999px);
  transform: translateZ(9999px);
}

.pnlm-title-box {
  position: relative;
  font-size: 20px;
  display: table;
  padding-left: 5px;
  margin-bottom: 3px;
  display: none;
}

.pnlm-author-box {
  position: relative;
  font-size: 12px;
  display: table;
  padding-left: 5px;
}

.pnlm-load-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 150px;
  margin: -75px 0 0 -100px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  text-align: center;
  font-size: 20px;
  display: none;
  color: #fff;
}

.pnlm-load-box p {
  margin: 20px 0;
}

.pnlm-lbox {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
  display: none;
}

.pnlm-loading {
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-name: pnlm-mv;
  -webkit-animation-name: pnlm-mv;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  height: 10px;
  width: 10px;
  background-color: #fff;
  position: relative;
}

@keyframes pnlm-mv {
  from {
    left: 0;
    top: 0;
  }
  25% {
    left: 10px;
    top: 0;
  }
  50% {
    left: 10px;
    top: 10px;
  }
  75% {
    left: 0;
    top: 10px;
  }
  to {
    left: 0;
    top: 0;
  }
}

@-webkit-keyframes pnlm-mv {
  from {
    left: 0;
    top: 0;
  }
  25% {
    left: 10px;
    top: 0;
  }
  50% {
    left: 10px;
    top: 10px;
  }
  75% {
    left: 0;
    top: 10px;
  }
  to {
    left: 0;
    top: 0;
  }
}

.pnlm-load-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 100px;
  margin: -50px 0 0 -100px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  text-align: center;
  font-size: 20px;
  display: table;
  color: #fff;
  cursor: pointer;
}

.pnlm-load-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.pnlm-load-button p {
  display: table-cell;
  vertical-align: middle;
}

.pnlm-info-box {
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 150px;
  margin: -75px 0 0 -100px;
  background-color: #000;
  border-radius: 3px;
  display: table;
  text-align: center;
  color: #fff;
  table-layout: fixed;
}

.pnlm-info-box a,
.pnlm-author-box a {
  color: #fff;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.pnlm-info-box p {
  display: table-cell;
  vertical-align: middle;
  padding: 0 5px 0 5px;
}

.pnlm-error-msg {
  display: none;
}

.pnlm-about-msg {
  font-size: 11px;
  line-height: 11px;
  color: #fff;
  padding: 5px 8px 5px 8px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  position: absolute;
  top: 50px;
  left: 50px;
  display: none;
  opacity: 0;
  -moz-transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.pnlm-about-msg a:link,
.pnlm-about-msg a:visited {
  color: #fff;
}

.pnlm-about-msg a:hover,
.pnlm-about-msg a:active {
  color: #eee;
}

.pnlm-hotspot-base {
  position: absolute;
  visibility: hidden;
  cursor: default;
  vertical-align: middle;
  top: 0;
  z-index: 1;
}
.pnlm-hotspot {
  height: 40px;
  width: 40px;
  border-radius: 30px;
}
.pnlm-hotspot:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.pnlm-hotspot.pnlm-info {
  background-image: url("../Assets/hotspot.svg");
}
.pnlm-hotspot.pnlm-scene {
  background-position: 0 -120px;
  background-image: url("../Assets/panoramJump.png");
}

div.pnlm-tooltip span {
  visibility: hidden;
  position: absolute;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  max-width: 200px;
  padding: 5px 10px;
  margin-left: -220px;
  cursor: default;
  font-size: 13px;
}
div.pnlm-tooltip:hover span {
  visibility: visible;
}
div.pnlm-tooltip:hover span:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  bottom: -20px;
  left: -10px;
  margin: 0 50%;
}

.pnlm-compass {
  position: absolute;
  width: 50px;
  height: 50px;
  right: 4px;
  bottom: 4px;
  border-radius: 25px;
  background-image: url("../Assets/compass.svg");
  cursor: default;
  display: none;
}

.pnlm-world {
  position: absolute;
  left: 50%;
  top: 50%;
}
.pnlm-face {
  position: absolute;
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
}

.pnlm-dragfix,
.pnlm-preview-img {
  position: absolute;
  height: 100%;
  width: 100%;
}

.pnlm-preview-img {
  background-size: cover;
  background-position: center;
}

.pnlm-lbar {
  width: 150px;
  margin: 0 auto;
  border: #fff 1px solid;
  height: 6px;
}

.pnlm-lbar-fill {
  background: #fff;
  height: 100%;
  width: 0;
}

.pnlm-lmsg {
  font-size: 12px;
}

.pnlm-fade-img {
  position: absolute;
  top: 0;
  left: 0;
}

.pnlm-pointer {
  cursor: pointer;
}
