#panorama {
  width: 100%;
  height: 458px;
  border-radius: 16px;
}
@media (min-width: 2559px) {
  #panorama {
    height: 1010px;
  }
} ;
